/*
* Nova HTML Template v 1.2
*
*/
/* HACKS ------------------------------------------------------------*/
.clear {
  clear: both;
  height: 1px;
}
.inv {
  display: none;
}
.alignleft {
  float: left;
  margin: 5px 10px 5px 0px;
}
/* GENERAL ------------------------------------------------------------*/
body {
  line-height: 1;
  color: #51565b;
  background: #f1f1f1 url(../images/bg/patterns/noise.png);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100%;
}
.wrapper {
  width: 966px;
  margin: 0px auto;
  position: relative;
}
#header {
  height: 81px;
  position: relative;
  z-index: 10;
  background: #2a2d30 url(../images/header-glow.png) top center no-repeat;
}
#main {
  width: 100%;
}
#content {
  min-height: 400px;
  overflow: hidden;
  margin-bottom: 40px;
  font-size: 14px;
  line-height: 1.5em;
}
#logo {
  position: absolute;
  top: 15px;
  left: 30px;
}
#application-title {
  position: absolute;
  top: 43px;
  left: 30px;
  color: #45BCED;
  font: normal 20px Ubuntu, arial;
  display: inline-block;
  width: 200px;
  text-align: center;
  text-decoration: none;
}
a {
  color: #37b2d1;
}
#content p {
  margin-bottom: 20px;
}
#content .line-divider {
  clear: both;
  border-bottom: 1px solid #d7d7d7;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
/* HEADLINE ------------------------------------------------------------*/
#headline {
  position: relative;
  width: 906px;
  margin: 20px auto 50px auto;
}
#headline > span.main {
  display: block;
  margin-right: 170px;
  font-family: 'Ubuntu', arial, serif;
  font-size: 26px;
  line-height: 1.5em;
  text-shadow: 1px 1px #ffffff;
}
#headline > span.sub {
  display: block;
  margin-right: 170px;
  font-size: 18px;
  line-height: 1.5em;
  text-shadow: 1px 1px #ffffff;
}
#headline #link {
  display: block;
  position: absolute;
  top: 30px;
  right: 0px;
}
/* MENU ------------------------------------------------------------*/
#menu {
  width: 100%;
  padding-top: 1px;
  margin-bottom: 0px;
  background: #f858ef;
  background: -webkit-gradient(linear, left top, right bottom, from(#f858ef), to(#ffe413));
  background: -moz-linear-gradient(left, #f858ef, #ffe413);
  background: -ms-linear-gradient(left, #f858ef, #ffe413);
  background: linear-gradient(left, #f858ef, #ffe413);
}
#menu-holder {
  width: 100%;
  height: 39px;
  background: url(../images/menu-bg.png) repeat-x;
}
/* NAVIGATION --------------------------------------------------------*/
#nav {
  display: block;
  position: absolute;
  top: 0px;
  left: 30px;
  height: 39px;
  width: 906px;
}
#nav > li {
  display: block;
  float: left;
  height: 100%;
  margin-right: 32px;
}
#nav > li > a {
  display: block;
  font-size: 16px;
  color: #bac0c9;
  text-decoration: none;
  margin-top: 10px;
  text-shadow: 0px -2px rgba(0, 0, 0, 0.8);
}
#nav > li > a > span.subheader {
  display: none;
  font-size: 11px;
  color: #656b74;
  font-style: italic;
  margin-top: 10px;
}
#nav > li.current-menu-item {
  background: url(../images/menu-arrow.png) no-repeat center bottom;
}
#nav > li:hover > a {
  color: #fff;
}
/* sub navigation */
#nav > li ul {
  display: block;
  margin-top: 33px;
  background: #1d2023;
  border-radius: 0px 10px 0px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -webkit-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.4);
}
#nav > li > ul ul {
  margin-top: 0px;
  border-radius: 0px 10px 10px 10px;
  -moz-border-radius: 0px 10px 10px 10px;
  -webkit-border-radius: 0px 10px 10px 10px;
}
#nav > li ul li a {
  padding: 10px 15px;
  display: block;
  font-size: 11px;
  color: #bac0c9;
  text-decoration: none;
}
#nav > li ul li a span {
  display: block;
}
#nav > li ul li a:hover {
  background: #000;
  color: #fff;
}
#nav > li ul li a span {
  transition: margin 200ms;
}
#nav > li ul li a:hover span {
  margin-left: 3px;
}
#nav > li ul li:last-child a:hover {
  /* last item its rounded at bottom */
  border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
}
/* breadcrumbs --------------------------------------------------------*/
#header #breadcrumbs {
  position: absolute;
  bottom: 11px;
  left: 30px;
  font-style: italic;
  color: #999;
  font-size: 10px;
}
#header #breadcrumbs a {
  color: #bfbdbd;
  text-decoration: none;
  background: url(../images/breadcrumb-slash.png) no-repeat top right;
  padding-right: 14px;
}
/* TITLE --------------------------------------------------------*/
#page-title {
  overflow: hidden;
  height: 103px;
  margin-bottom: 30px;
  background: url(../images/tabs-divider.png) repeat-x bottom center;
  -webkit-text-shadow: 1px 1px #ffffff;
  text-shadow: 1px 1px #ffffff;
}
#page-title .title {
  display: block;
  float: left;
  font-family: 'Ubuntu', arial, serif;
  font-size: 40px;
  line-height: 103px;
  margin-left: 30px;
}
#page-title .subtitle {
  display: block;
  float: left;
  margin-left: 30px;
  font-size: 14px;
  margin-top: 4px;
  line-height: 103px;
  color: #929191;
  font-style: italic;
}
#page-title .qr-code {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  -moz-transition: all ease 0.8s;
  -o-transition: all ease 0.8s;
  -webkit-transition: all ease 0.8s;
  transition: all ease 0.8s;
  border: solid 0 #fff;
}
#page-title .qr-code:hover {
  border-width: 10px;
  width: 133px;
  cursor: none;
  z-index: 10;
}
/* HEADERS --------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4em;
  font-family: 'Ubuntu', arial, serif;
  font-weight: lighter;
}
h1 {
  font-size: 30px;
}
h2 {
  font-size: 28px;
}
h3 {
  font-size: 26px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 20px;
}
/* COLUMNS LAYOUT----------------------------------------------------------*/
.one-half,
.one-third,
.two-third,
.three-fourth,
.one-fourth {
  float: left;
  margin-right: 30px;
  position: relative;
}
.one-half {
  width: 438px;
}
.one-third {
  width: 282px;
}
.one-fourth {
  width: 204px;
}
.two-third {
  width: 594px;
}
.three-fourth {
  width: 672px;
}
.last {
  clear: right;
  margin-right: 0 !important;
}
/* SEARCH --------------------------------------------------------*/
.top-search {
  display: none;
  position: absolute;
  top: 11px;
  right: 30px;
}
#searchform #s {
  color: #7e7e7e;
  font-size: 10px;
  width: 180px;
  height: 28px;
  padding: 0px 35px 0px 10px;
  border: 0;
  background: url(../images/search.png) no-repeat transparent;
}
#searchform #searchsubmit {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 30px;
  height: 25px;
  border: 0px;
  background: transparent;
  cursor: pointer;
}
/* FILTER --------------------------------------------------------*/
#content .filter {
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
#content .filter li {
  display: block;
  float: left;
  font-size: 12px;
  line-height: 14px;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 3px 10px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  background: #d1d1d1;
  background: -webkit-gradient(linear, left top, left bottom, from(#d1d1d1), to(#f1f1f1));
  background: -moz-linear-gradient(top, #d1d1d1, #f1f1f1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d1d1d1', endColorstr='#f1f1f1', GradientType=0);
  border-top: 1px solid #bcbcbc;
}
#content .filter li a {
  display: block;
  color: #606060;
  text-decoration: none;
  width: 100%;
  height: 100%;
}
#content .filter li:first-child:hover {
  background: none;
}
#content .filter li.active,
#content .filter li:hover {
  background: #000;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}
#content .filter li:hover a,
#content .filter li.active a {
  color: #f1f1f1;
}
#content .filter li:first-child,
#content .filter li:first-child:hover {
  background: none;
  border: none;
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
/* TWITTER --------------------------------------------------------*/
#twitter {
  position: relative;
  background: #f2f1f1;
  height: 80px;
  width: 100%;
  background: #f2f1f1 url(../images/twitter-border.png) repeat-x;
}
#twitter #bird {
  position: absolute;
  top: 2px;
  left: 70px;
}
#twitter #prev-tweet {
  display: block;
  position: absolute;
  top: 21px;
  left: 30px;
  width: 30px;
  height: 35px;
  background: transparent url(../images/prev-arrow.png) no-repeat top left;
}
#twitter #next-tweet {
  display: block;
  position: absolute;
  top: 21px;
  right: 30px;
  width: 30px;
  height: 35px;
  background: transparent url(../images/next-arrow.png) no-repeat top right;
}
#twitter #tweets .tweet_list {
  display: block;
  position: absolute;
  top: 1px;
  left: 200px;
  width: 700px;
  height: 75px;
}
#twitter #tweets .tweet_list li {
  display: block;
  width: 700px;
  height: 80px;
  /* original height 170px */
  font-size: 16px;
  line-height: 1.5em;
  font-style: italic;
  color: #838383;
  padding-top: 25px;
}
#twitter #tweets .tweet_list li .tweet_time a {
  text-decoration: none;
  color: #ccc;
}
#twitter #tweets .tweet_list li a {
  text-decoration: none;
  color: #37b2d1;
}
#twitter #tweets p.loading {
  margin-left: 200px;
  color: #ccc;
  padding-top: 16px;
  font-style: italic;
}
/* PAGES --------------------------------------------------------*/
#page-content {
  float: left;
  width: 906px;
  margin-left: 30px;
  line-height: 1.5em;
}
/* PORTFOLIO --------------------------------------------------------*/
#projects-list {
  overflow: hidden;
}
#projects-list .project {
  position: relative;
  float: left;
  width: 438px;
  margin-left: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  background: url(../images/portfolio-divider.png) no-repeat bottom center;
}
#projects-list .project h1 a {
  display: block;
  text-decoration: none;
  margin-bottom: 20px;
  color: #51565b;
}
.project-shadow {
  background: url(../images/shadow-project.png) no-repeat 0px 267px;
}
#projects-list .project .project-thumbnail {
  position: relative;
  overflow: hidden;
  width: 438px;
  height: 267px;
  margin-bottom: 20px;
  background: #f1f1f1;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#projects-list .project .project-thumbnail .cover {
  position: absolute;
  top: 0px;
  left: 0px;
}
#projects-list .project .project-thumbnail .meta {
  display: block;
  width: 252px;
  height: 207px;
  font-size: 12px;
  line-height: 2em;
  position: absolute;
  top: 30px;
  left: 30px;
}
#projects-list .project .read-more {
  display: block;
  position: absolute;
  bottom: -17px;
  left: 160px;
}
/* PROJECT --------------------------------------------------------*/
.project-column {
  width: 438px;
  float: left;
  margin-left: 30px;
}
.project-column .project-thumbnail {
  overflow: hidden;
  width: 438px;
  height: 267px;
  margin-bottom: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#content .project-column .meta a {
  display: inline;
  font-size: 12px;
  color: #fff;
  padding: 3px 10px;
  text-decoration: none;
  background: #363636;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#content .project-column .meta a:hover {
  background: #000;
}
.project-column .project-title {
  margin-bottom: 20px;
}
.project-column .portfolio-left-shadow {
  height: 26px;
  margin-bottom: 20px;
  background: url(../images/portfolio-left-shadow.png) no-repeat center bottom;
}
/* PROJECT-GALLERY -----------------------------------------------*/
.project-column .project-gallery {
  display: block;
  overflow: hidden;
  width: 468px;
}
.project-column .project-gallery li {
  display: block;
  float: left;
  overflow: hidden;
  background: url(../images/plus-sign.png) center center no-repeat;
  width: 204px;
  height: 148px;
  margin-right: 30px;
  margin-top: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
/* GALLERY -----------------------------------------------*/
#content .filter li.selected {
  background: #000;
}
/* PROJECT-RELATED -----------------------------------------------*/
.related-title {
  margin-left: 30px;
}
.related-projects {
  display: block;
  overflow: hidden;
}
.related-projects li {
  display: block;
  float: left;
  overflow: hidden;
  width: 204px;
  height: 280px;
  margin-left: 30px;
  margin-top: 30px;
}
.related-projects li strong {
  display: block;
}
.related-projects li a.box {
  display: block;
  overflow: hidden;
  width: 204px;
  height: 148px;
  margin-bottom: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
.related-projects li a.box img {
  opacity: 0.7;
}
.related-projects li:hover a.box img {
  opacity: 1;
}
#content .related-projects li a {
  text-decoration: none;
  color: #999;
}
/* GALLERY -----------------------------------------------*/
#content .gallery {
  display: block;
  overflow: hidden;
}
#content .gallery li {
  display: block;
  float: left;
  overflow: hidden;
  background: url(../images/plus-sign.png) center center no-repeat;
  width: 204px;
  height: 148px;
  margin-left: 30px;
  margin-bottom: 30px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#content .gallery.three-cols li {
  width: 282px;
  height: 267px;
}
#content .gallery.two-cols li {
  width: 438px;
  height: 267px;
}
#content .gallery li a {
  display: block;
}
/* BLOG --------------------------------------------------------*/
#posts {
  float: left;
  width: 624px;
}
#posts .post {
  position: relative;
  margin-left: 30px;
  margin-bottom: 40px;
}
#comments-block {
  position: relative;
  margin-left: 30px;
  margin-top: 40px;
  margin-bottom: 30px;
  padding-top: 80px;
}
#posts.single .post {
  border-bottom: none;
}
#posts .post:last-child {
  border-bottom: none;
}
#posts h1 a {
  display: block;
  margin-bottom: 20px;
  width: 514px;
  text-decoration: none;
  color: #51565b;
}
#posts .n-comments {
  display: block;
  position: absolute;
  top: 10px;
  right: 0px;
  width: 57px;
  height: 41px;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  background: url(../images/balloon.png) no-repeat;
}
#posts.single .post .n-comments {
  position: static;
}
.post .thumb-shadow {
  background: url(../images/shadow-blog.png) no-repeat 0px 270px;
}
#posts .post .post-thumbnail {
  position: relative;
  overflow: hidden;
  width: 596px;
  height: 270px;
  margin-bottom: 20px;
  background: #f1f1f1;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#posts .post .post-thumbnail .cover {
  position: absolute;
  top: 0px;
  left: 0px;
}
#posts .post .the-excerpt {
  padding-bottom: 40px;
  background: url(../images/post-divider.png) center bottom no-repeat;
}
#posts .post .post-thumbnail .meta {
  display: block;
  width: 252px;
  height: 207px;
  font-size: 12px;
  line-height: 2em;
  position: absolute;
  top: 30px;
  left: 30px;
}
#posts.single .post .meta {
  display: block;
  background: #f1f1f1;
  padding: 20px;
  border-color: #e7e6e6 #ececec #ececec #ececec;
  border-width: 3px 1px 1px 1px;
  border-style: solid;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#posts .post .read-more {
  display: block;
  position: absolute;
  bottom: -18px;
  left: 260px;
}
#posts .post .meta a {
  text-decoration: none;
}
#posts .post .meta-tags {
  overflow: hidden;
}
#posts .post .meta-tags a {
  float: left;
  font-size: 12px;
  line-height: 14px;
  color: #f1f1f1;
  background: #696969;
  padding: 3px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#posts .post .meta-tags a:hover {
  background: #000;
}
/* COMMENTS-BLOCK ------------------------------------------------------------*/
#comments-block .n-comments {
  left: 0px;
}
#comments-block .n-comments {
  left: 0px;
}
#comments-block .n-comments-text {
  display: block;
  position: absolute;
  top: 4px;
  left: 68px;
  font-family: 'Ubuntu', arial, serif;
  font-size: 28px;
}
/* COMMENTSLIST ------------------------------------------------------------*/
.commentlist {
  display: block;
}
.commentlist li {
  display: block;
  margin-bottom: 0px;
  padding: 10px 10px 10px 0px;
  line-height: 1.5em;
}
.commentlist ul {
  padding-left: 40px;
  border-left: 1px dashed #ccc;
}
.commentlist li .comment-body {
  overflow: hidden;
  position: relative;
  padding: 0px 0px 20px 0px;
}
.commentlist li img {
  display: block;
  float: left;
  margin: 0px 12px 20px 0px;
}
.commentlist .meta-date {
  display: block;
  float: right;
}
#content .commentlist .reply a {
  display: block;
  text-decoration: none;
  float: right;
  padding: 2px 8px;
  background: #ccc;
  color: #f1f1f1;
  font-size: 12px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
/* COMMENTS PAGINATION ------------------------------------------------------------*/
#content .comments-pagination {
  clear: both;
  overflow: hidden;
  padding: 20px 0;
  position: relative;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 10px;
}
#content .comments-pagination span,
#content .comments-pagination a {
  display: block;
  float: left;
  margin: 2px 4px 2px 0;
  padding: 6px 9px 5px 9px;
  text-decoration: none;
  width: auto;
  color: #fff;
  background: #555;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
}
#content .comments-pagination a:hover {
  color: #fff;
  background: #000000;
}
#content .comments-pagination .current {
  padding: 6px 9px 5px 9px;
  background: #000000;
  color: #fff;
}
/* LEAVE COMMENT --------------------------------------------------------*/
.leave-comment {
  margin-top: 20px;
  padding-top: 30px;
  background: url(../images/post-divider-inverted.png) no-repeat;
}
.leave-comment h2 {
  margin-bottom: 20px;
}
/* CONTACT --------------------------------------------------------*/
.one-column {
  width: 438px;
  float: left;
  margin-left: 30px;
}
/* FORMS ------------------------------------------------------------*/
#sc-contact-form,
#contactForm,
#commentform,
#contactForm,
.admin-form,
.form {
  margin-bottom: 18px;
}
#sc-contact-form input[type=number],
#contactForm input[type=number],
#commentform input[type=number],
#contactForm input[type=number],
.admin-form input[type=number],
.form input[type=number],
#sc-contact-form input[type=file],
#contactForm input[type=file],
#commentform input[type=file],
#contactForm input[type=file],
.admin-form input[type=file],
.form input[type=file],
#sc-contact-form input[type=text],
#contactForm input[type=text],
#commentform input[type=text],
#contactForm input[type=text],
.admin-form input[type=text],
.form input[type=text],
#sc-contact-form input[type=email],
#contactForm input[type=email],
#commentform input[type=email],
#contactForm input[type=email],
.admin-form input[type=email],
.form input[type=email],
#sc-contact-form input[type=password],
#contactForm input[type=password],
#commentform input[type=password],
#contactForm input[type=password],
.admin-form input[type=password],
.form input[type=password],
#sc-contact-form textarea,
#contactForm textarea,
#commentform textarea,
#contactForm textarea,
.admin-form textarea,
.form textarea,
#sc-contact-form select,
#contactForm select,
#commentform select,
#contactForm select,
.admin-form select,
.form select {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  color: #606060;
  font-size: 14px;
}
#sc-contact-form input[type=number],
#contactForm input[type=number],
#commentform input[type=number],
#contactForm input[type=number],
.admin-form input[type=number],
.form input[type=number],
#sc-contact-form input[type=file],
#contactForm input[type=file],
#commentform input[type=file],
#contactForm input[type=file],
.admin-form input[type=file],
.form input[type=file],
#sc-contact-form input[type=text],
#contactForm input[type=text],
#commentform input[type=text],
#contactForm input[type=text],
.admin-form input[type=text],
.form input[type=text],
#sc-contact-form input[type=email],
#contactForm input[type=email],
#commentform input[type=email],
#contactForm input[type=email],
.admin-form input[type=email],
.form input[type=email],
#sc-contact-form input[type=password],
#contactForm input[type=password],
#commentform input[type=password],
#contactForm input[type=password],
.admin-form input[type=password],
.form input[type=password],
#sc-contact-form select,
#contactForm select,
#commentform select,
#contactForm select,
.admin-form select,
.form select {
  width: 282px;
}
#sc-contact-form textarea,
#contactForm textarea,
#commentform textarea,
#contactForm textarea,
.admin-form textarea,
.form textarea {
  width: 380px;
}
#sc-contact-form #submit,
#contactForm #submit,
#commentform #submit,
#contactForm #submit,
.admin-form #submit,
.form #submit,
#sc-contact-form .submit,
#contactForm .submit,
#commentform .submit,
#contactForm .submit,
.admin-form .submit,
.form .submit {
  min-width: 76px;
  margin: 0;
  padding: 5px;
  color: #f1f1f1;
  background: #515558;
  background: -webkit-gradient(linear, left top, left bottom, from(#515558), to(#131313));
  background: -moz-linear-gradient(top, #515558, #131313);
  background: -ms-linear-gradient(top, #515558, #131313);
  background: linear-gradient(top, #515558, #131313);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
}
#sc-contact-form #submit:hover,
#contactForm #submit:hover,
#commentform #submit:hover,
#contactForm #submit:hover,
.admin-form #submit:hover,
.form #submit:hover,
#sc-contact-form .submit:hover,
#contactForm .submit:hover,
#commentform .submit:hover,
#contactForm .submit:hover,
.admin-form .submit:hover,
.form .submit:hover,
#sc-contact-form #submit:focus,
#contactForm #submit:focus,
#commentform #submit:focus,
#contactForm #submit:focus,
.admin-form #submit:focus,
.form #submit:focus,
#sc-contact-form .submit:focus,
#contactForm .submit:focus,
#commentform .submit:focus,
#contactForm .submit:focus,
.admin-form .submit:focus,
.form .submit:focus {
  background: #696f73;
  background: -webkit-gradient(linear, left top, left bottom, from(#131313), to(#515558));
  background: -moz-linear-gradient(top, #131313, #515558);
  background: -ms-linear-gradient(top, #131313, #515558);
  background: linear-gradient(top, #131313, #515558);
}
#sc-contact-form label,
#contactForm label,
#commentform label,
#contactForm label,
.admin-form label,
.form label {
  display: block;
  font-size: 14px;
  line-height: 2.5em;
  font-weight: lighter;
}
#sc-contact-form label:after,
#contactForm label:after,
#commentform label:after,
#contactForm label:after,
.admin-form label:after,
.form label:after {
  content: ' :';
}
#loginForm .form {
  width: 314px;
  background: #57737f;
  color: #fff;
  border-color: #fff;
  /*margin-left:auto;
    margin-right:auto;*/
}
/* PAGER ------------------------------------------------------------*/
.pager {
  padding-top: 0px;
  overflow: hidden;
  display: block;
  height: 30px;
  margin-top: 20px;
  margin-left: 30px;
  margin-bottom: 20px;
  font-size: 10px;
}
.pager li {
  display: block;
  width: 26px;
  height: 26px;
  margin-right: 8px;
  float: left;
}
#content .pager li a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 26px;
  text-align: center;
  text-decoration: none;
  color: #606060;
  border-radius: 33px;
  -moz-border-radius: 33px;
  -webkit-border-radius: 33px;
  background: #d1d1d1;
  background: -webkit-gradient(linear, left top, left bottom, from(#d1d1d1), to(#f1f1f1));
  background: -moz-linear-gradient(top, #d1d1d1, #f1f1f1);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d1d1d1', endColorstr='#f1f1f1', GradientType=0);
  border-top: 1px solid #bcbcbc;
}
#content .pager li:hover a,
#content .pager li.active a {
  color: #fff;
  background: #515558;
  background: -webkit-gradient(linear, left top, left bottom, from(#515558), to(#131313));
  background: -moz-linear-gradient(top, #515558, #131313);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#515558', endColorstr='#131313', GradientType=0);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border-top: none;
}
/* SIDEBAR ------------------------------------------------------------*/
#sidebar {
  float: left;
  width: 282px;
  margin-left: 30px;
}
#sidebar > li {
  margin-bottom: 20px;
}
#sidebar > li h6 {
  padding-bottom: 20px;
}
#sidebar ul > li {
  font-size: 14px;
  line-height: 40px;
  text-decoration: none;
  border-bottom: 1px solid #d7d7d7;
}
#sidebar ul > li:last-child {
  border-bottom: none;
}
#sidebar ul > li a {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #696969;
}
#sidebar ul > li:hover {
  background: #f1f1f1;
}
/* FOOTER - COLS ------------------------------------------------------------*/
#footer {
  position: relative;
  background: #2a2d30 url(../images/footer-glow.png) no-repeat top center;
  color: #fff;
}
/* FOOTER - COLS ------------------------------------------------------------*/
#footer-cols {
  display: block;
  overflow: hidden;
  color: #bbbaba;
  line-height: 1.5em;
}
#footer-cols li.col {
  display: block;
  float: left;
  margin-bottom: 30px;
  margin-left: 30px;
  width: 45%;
  font-size: 12px;
}
#footer-cols li.col > h6 {
  margin-top: 30px;
  margin-bottom: 20px;
  color: #d7d7d7;
  -webkit-text-shadow: 0px -2px rgba(0, 0, 0, 0.8);
  text-shadow: 0px -2px rgba(0, 0, 0, 0.8);
}
#footer-cols li.col ul {
  display: block;
}
#footer-cols li.col ul li {
  display: block;
  line-height: 1.7em;
}
#footer-cols li.clear-col {
  clear: left;
}
/* FOOTER BOTTOM --------------------------------------------------------*/
#bottom {
  position: relative;
  width: 100%;
  font-size: 12px;
  line-height: 1.5em;
  line-height: 60px;
  color: #868686;
  background: #222426 url(../images/bottom-shadow.png) repeat-x;
}
#bottom #bottom-text {
  width: 600px;
  overflow: hidden;
  margin-left: 30px;
}
#bottom a {
  color: #868686;
  text-decoration: none;
}
#bottom #to-top {
  position: absolute;
  right: 30px;
  top: -22px;
  width: 33px;
  height: 33px;
  background: url(../images/to-top.png) no-repeat;
  cursor: pointer;
}
/* SOCIAL --------------------------------------------------------*/
.social {
  position: absolute;
  top: 15px;
  right: 0px;
  display: block;
  overflow: hidden;
}
.social li {
  display: block;
  width: 32px;
  height: 32px;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
#bottom .social a {
  display: block;
  width: 32px;
  height: 32px;
  text-indent: -9000px;
}
#bottom .social a:hover {
  background-position: 0px -32px;
}
small {
  font-size: smaller;
}
#footer-cols li.col ul li.page_item {
  display: block;
  line-height: 2.5em;
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #000;
}
#footer-cols li.col ul li:first-child {
  border-top: none;
}
#footer-cols li.col ul li:last-child {
  border-bottom: none;
}
#footer-cols li.col ul li a {
  text-decoration: none;
  color: #bbbaba;
}
#footer-cols li.col ul li.page_item a {
  display: block;
  text-decoration: none;
  color: #bbbaba;
  padding-left: 17px;
  background: url(../images/footer-bullet.png) no-repeat 0px 10px;
  transition: padding 200ms;
}
#footer-cols li.col ul li.page_item a:hover {
  color: #fff;
  padding-left: 22px;
}
::-webkit-input-placeholder {
  color: #7e7e7e;
}
::-moz-placeholder {
  color: #7e7e7e;
}
/* firefox 19+ */
:-ms-input-placeholder {
  color: #7e7e7e;
}
/* ie */
input:-moz-placeholder {
  color: #7e7e7e;
}
#header q {
  position: absolute;
  left: 250px;
  top: 39px;
  quotes: "« " " »";
  display: inline-block;
  color: #7E7E7E;
  font: italic 14px Ubuntu, arial;
}
#header q:before {
  content: open-quote;
}
#header q:after {
  content: close-quote;
}
fieldset {
  border: solid 1px #A4A4A4;
  border-radius: 10px;
  position: relative;
  padding: 10px;
  margin: 10px 0;
}
fieldset legend {
  position: absolute;
  top: -12px;
  left: 10px;
  background: #e5e5e5;
  padding: 0 10px;
  font-weight: bold;
}
#disqus_thread {
  margin-top: 20px;
}
#fancybox-title {
  top: 6px;
}
.bugcentre-notification {
  position: fixed;
  right: 10px;
  bottom: -1000px;
  z-index: 2000;
  min-width: 200px;
  max-width: 300px;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font: normal 13px arial;
}
.form input.input-validation-error {
  border-color: #D8000C;
}
.field-validation-error {
  display: block;
  font-size: 14px;
  color: #D8000C;
}
.flexigrid a.icon-edit,
.flexigrid a.icon-view,
.flexigrid a.icon-delete,
.flexigrid a.icon-download {
  color: #000;
  text-decoration: none;
  font-size: 18px;
  position: absolute;
  left: 50%;
  margin-left: -9px;
  top: 7px;
}
.flexigrid .trSelected a.icon-edit,
.flexigrid .trSelected a.icon-view,
.flexigrid .trSelected a.icon-delete {
  color: #fff;
}
.flexigrid .icon-veryhigh {
  position: relative;
  height: 10px;
}
.flexigrid .icon-veryhigh:before {
  content: 'F';
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 5px;
  color: red;
}
.flexigrid .icon-high {
  position: relative;
  height: 10px;
}
.flexigrid .icon-high:before {
  content: 'E';
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 5px;
  color: red;
}
.flexigrid .icon-medium {
  position: relative;
  height: 10px;
}
.flexigrid .icon-medium:before {
  content: 'D';
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 5px;
  color: #FDB958;
}
.flexigrid .icon-low {
  position: relative;
  height: 10px;
}
.flexigrid .icon-low:before {
  content: 'C';
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 5px;
  color: #2E9DFF;
}
.flexigrid .icon-verylow {
  position: relative;
  height: 10px;
}
.flexigrid .icon-verylow:before {
  content: 'B';
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -11px;
  top: 5px;
  color: #2E9DFF;
}
.flexigrid .icon-unset {
  position: relative;
  height: 10px;
}
.flexigrid .icon-unset:before {
  font: bold 18px arial !important;
  color: grey;
  content: "?";
  font-size: 22px;
  position: absolute;
  left: 50%;
  margin-left: -7px;
  top: 5px;
}
code {
  white-space: pre-line;
  -ms-word-break: break-all;
  word-break: break-all;
}
.breadcrumb {
  overflow: hidden;
}
.breadcrumb .breadcrumb-link {
  display: inline-block;
  float: left;
  font-weight: 600;
}
.breadcrumb .breadcrumb-link + .breadcrumb-link:before {
  font-weight: normal;
  content: " > ";
  color: #49494b;
  text-decoration: none;
  display: inline-block;
  float: left;
  padding: 0 5px;
}
.breadcrumb .breadcrumb-link:last-child {
  color: #49494b;
  text-decoration: none;
}
::selection {
  background: #49494b;
  color: #fff;
}
::-moz-selection {
  background: #49494b;
  color: #fff;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 2px 1px 1px 2px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.button {
  min-width: 76px;
  margin: 0;
  padding: 5px;
  color: #f1f1f1;
  background: #515558;
  background: -webkit-gradient(linear, left top, left bottom, from(#515558), to(#131313));
  background: -moz-linear-gradient(top, #515558, #131313);
  background: -ms-linear-gradient(top, #515558, #131313);
  background: linear-gradient(top, #515558, #131313);
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
}
.button:hover,
.button:focus {
  background: #696f73;
  background: -webkit-gradient(linear, left top, left bottom, from(#131313), to(#515558));
  background: -moz-linear-gradient(top, #131313, #515558);
  background: -ms-linear-gradient(top, #131313, #515558);
  background: linear-gradient(top, #131313, #515558);
}
input.ctrlc {
  cursor: pointer;
}
.auto-font-size {
  white-space: pre;
}
/*# sourceMappingURL=/Content/Styles/style.css.map */